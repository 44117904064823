import React from 'react';
import {string, func} from 'prop-types';
import {connect} from 'react-redux';
import RadioButton from '../../RadioButton';
import InputLine from '../../InputLine';
import './styles.scss';
import Close from '../../Close';

const lengthOfYear = 365.25;

class AgeMenu extends React.Component {
    state = {
        age: undefined, // number
        direction: undefined, /* < or > */
        format: undefined, /* years or days */
    };

    componentDidMount() {
        const value = this.props.value || '';
        let age = value.substring(1, value.length) || '0';
        age = parseInt(age) || 0;
        const format = age < 100 ? 'years' : 'days';

        const direction = value[0] || '<';
        this.setState({
            direction,
            age,
            format,
        });
    }

    render() {
        const {t} = this.props;
        const {direction, age, format} = this.state;
        let input = age;
        if (format === 'years') {
            input = age / lengthOfYear;
        }
        input = `${Math.trunc(input)}`;
        return (
            <div className="age-menu">
                <Close onClick={this.props.onClose}/>
                <div className="age-format">
                    <RadioButton checked={direction === '<'} label={t.less} onChange={() => this.setState({direction: '<'})}/>
                    <RadioButton checked={direction === '>'} label={t.more} onChange={() => this.setState({direction: '>'})} />
                </div>
                <div className="age-input">
                    <label>{t.than}</label>
                    <InputLine onChange={(age) => this.handleAgeChange(age)} value={input}/>
                </div>
                <div className="age-direction">
                    <RadioButton checked={format === 'days'} label={t.days} onChange={() => this.setState({format: 'days'})}/>
                    <RadioButton checked={format === 'years'} label={t.years} onChange={() => this.setState({format: 'years'})}/>
                </div>
            </div>
        );
    }

    handleAgeChange = (age) => {
        if (!age) {
            this.setState({age: ''});
        } else {
            age = parseInt(age);
            if (!Number.isNaN(age)) {
                if (this.state.format === 'years') {
                    this.setState({ age: age * lengthOfYear});
                } else {
                    this.setState({ age });
                }
            }
        }
    };

    shouldComponentUpdate(nextProps, nextState) {
        return Object.entries(nextState).some(([k, v]) => v !== this.state[k]);
    }

    componentDidUpdate() {
        const { onChange } = this.props;
        const { age, direction } = this.state;
        onChange({value: direction + Math.trunc(age)});
    }
}

AgeMenu.propTypes = {
    value: string,
    onChange: func,
    onClose: func,
};

export default connect(({locales: {t}}) => ({t}))(AgeMenu);
